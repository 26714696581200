/* eslint-disable react/jsx-no-target-blank */
import * as React from "react"
import { useClasses } from '../lib/ReactUtils'
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import '../styles/TwoCols'

// windows binary

export default function DownloadPage() {
  const root = useClasses()
  return (
    <Layout full={false}>
      <SEO title='Download' description='Download OneOffice mobile and desktop apps'/>
      <main className="main" ref={root}>

        <div className="heading-separator"/>
        <h1 className="title">Downloads</h1>
        <h2 className="subtitle">Work on the go or offline</h2>



        <div className="twocols-tight mt-50" id="editor">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/download/oo-editor.png" alt="Editor"/>
          </div>
          <div className="twocols-col-text-right">
            <div className="subheading">OneOffice Productivity Suite v7.5</div>
            <div className="heading">A complete document editor for your desktop</div>
            <div className="description">
              <p>
                OO-Editor is a full document editor (Word, Excel, PowerPoint, Visio and all other standard formats).
                It also includes a full SQL-compliant database (just like Access) and an Equation Editor.
                A must have for the modern team! 
                <span className="text-base text-gray-700"> -- Note: MacOS version coming soon!</span>
              </p>
              <p className="quote">If you haven't yet installed the Microsoft VC++ redistributable libraries, <a href="https://aka.ms/vs/17/release/vc_redist.x64.exe" target="_blank">click here to download from Microsoft</a> or get it yourself from <a href="https://docs.microsoft.com/en-us/cpp/windows/latest-supported-vc-redist?view=msvc-170" target="_blank">microsoft.com</a></p>
            </div>
          </div>
        </div>
        <div>
          <div className='w-full my-15 text-center'>
            <a href="https://caoneofficecdn.s3.us-east-1.amazonaws.com/binaries/OO-Editor_7.5.0_Win_x86-64.msi" target="_blank" className='button cta lg'>Download for Windows NOW</a>
          </div>
        </div>



        <div className="twocols-tight mt-50" id="mail">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/download/oo-mail.png" alt="Mail"/>
          </div>
          <div className="twocols-col-text-right">
            <div className="subheading">OneOfficeMail v1.10.5</div>
            <div className="heading">Full email client on your desktop</div>
            <div className="description">
              <p>The smartest email program on the planet!
                Runs in the background so not to drain your battery. Comes packed with phishing detection, real-time notifications, folders and more ...
              </p>
              <p>
                OneOfficeMail does not need administrator privileges to install, as it runs from your local directory.
              </p>
              <span className="text-base text-gray-700"> -- Note: MacOS version coming soon!</span>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <div className='w-full my-15 text-center'>
            <a href="https://caoneofficecdn.s3.us-east-1.amazonaws.com/binaries/OO-Mail_1.10.5.exe" target="_blank" className='button cta lg'>Download for Windows NOW</a>
          </div>
        </div>

        
      </main>
    </Layout>

  )
}
